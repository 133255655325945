body, html, #root{
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin: 0
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#tag-component .swiper-button-next, #tag-component .swiper-button-prev {
  top:35px;
}

/* change style arrow swiper */
.swiper-button-next, .swiper-button-prev { 
  color: rgba(0,0,0, .20)!important;
  width:35px!important;
  height: 35px!important;
  z-index: 10000000;
  border-radius: 50px;
  background-color: rgba(0,0,0, .25);

}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  font-size: 24px!important;
  color: rgba(255,255,255, 1)!important;

}
.swiper-button-next:hover, .swiper-button-prev:hover { 
  color: rgba(0,0,0, .50)!important;
  font-size: 42px!important;
}

/** Widget fresh */
#fc_frame, #fc_frame.fc-widget-normal {
  bottom:64px!important;
}

#input-search{
  border:none!important;
}

#bottom-nav .MuiBottomNavigationAction-label{
  font-size: 9px;
}
#bottom-nav .MuiBottomNavigationAction-root{
  min-width: 0px;
}